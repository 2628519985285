import request from '@/utils/request'


export const getAppointments: any = (dentistId: any) => {
    return request({
        url: `/appointment/dentist/${dentistId}`,
        method: 'get',
    })
}

export const getAppointmentType: any = () => {
    return request({
        url: `/appointment/appointmentType`,
        method: 'get',
    })
}

export const getAppointment: any = (id:string) => {
    return request({
        url: `/appointment/${id}`,
        method: 'get',
    })
}